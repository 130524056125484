<template>
  <v-list
    class="pa-0 ma-0 fonte pt-1"
    dense
    :dark="$theme.dark"
    :light="$theme.light"
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="(seller, i) in filtereds">
      <v-list-item
        @click="open(seller)"
        style="border-radius: 6px;"
        :key="seller._id"
        class="fonte pl-1 pr-1"
      >
        <v-list-item-content class="ml-1">
          <v-list-item-title>
            <span class="fonte">
              {{ seller.name }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle class="fonteMini">
            <v-chip
              x-small
              class="mr-1"
              :style="`color: ${$theme.primary}; border-radius: 3px;`"
            >
              <v-icon :color="$theme.primary" size="11" class="mr-2"
                >mdi-card-account-details-outline</v-icon
              >
              {{ seller.cpf }}
            </v-chip>
            <v-chip
              x-small
              :style="`color: ${$theme.primary}; border-radius: 3px;`"
            >
              <v-icon :color="$theme.primary" size="11" class="mr-2"
                >mdi-crown</v-icon
              >
              {{ seller.is_supervisor ? 'SUPERVISOR' : 'VENDEDOR' }}
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="fonteMini">
              {{ seller.email }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="i"></v-divider>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["get_seller"])
  },
  methods: {
    open(client) {
      this.$store.commit("set_seller", client);
    }
  }
};
</script>
